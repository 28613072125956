import { TableOptions } from '@ultra/share/components/UTable/types/types';
import { UseTable } from '@ultra/share/components/UTable/useTable';
import { apiUsdkH5ConfigList } from '@/api/usdk';
import { apigetUserNames } from '@/api/user';

const useH5PageManage = () => {
  const columnsH5:TableOptions[] = [
		{ label: '页面ID', prop: 'webId' },
		{ label: '功能说明', prop: 'name' },
		{ label: '跳转地址', prop: 'webUrl', slot: 'webUrl' },
		{ label: '屏幕方向', prop: 'orientation', slot: 'orientation' },
		{ label: '修改人', prop: 'updateUser' },
		{ label: '修改时间', prop: 'updateTime' },
    { label: '操作', action: true }
	];

  const getH5PageTableData = async ({ gid }: {gid: number}) => {
    const res = await apiUsdkH5ConfigList({
      gid
    });
    // tableData.value = res.data;
    if (res.code === 0) {
      const uids = res.data.map((item: any) => item.updateUser);
      const data = Array.from(new Set(uids));
      const result = await apigetUserNames({
        uids: data.join(',')
      });
      if (result.code === 0) {
        res.data.forEach((item: any) => {
          const user = result.data.find((user: any) => user.id === item.updateUser);
          item.updateUser = user ? user.user_name : '-';
        });
      }
      tableData.value = res.data;
    }
  };

  const {
		tableData,
		tableLoading
	} = UseTable(getH5PageTableData);

  return {
    columnsH5,
    tableDataH5: tableData,
    tableLoadingH5: tableLoading,
    getH5PageTableData
  };
};

export { useH5PageManage };
